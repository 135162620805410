import type { DefaultConfigOptions } from "@formkit/vue";
import { createProPlugin, datepicker, mask, taglist, repeater } from '@formkit/pro'
import { dragHandle, close, caretDown, caretDown as select, down, date, trash, arrowUp, arrowDown, add, file } from '@formkit/icons'

const proPlugin = createProPlugin(import.meta.env.VITE_FORMKIT_PRO_KEY, {
  mask, datepicker, taglist, repeater
})
import { rootClasses } from "./formkit.theme";

const config: DefaultConfigOptions = {
  plugins: [proPlugin],
  icons: { close, caretDown, dragHandle, down, select, date, trash, arrowUp, arrowDown, add, file },
  config: {
    rootClasses
  },
};

export default config;
