// HamrsDotAppService.ts
import { hamrsDotAppURL, requireSync } from '@/config'
export class HamrsSyncBadApiKeyError extends Error {
  constructor(message: string = 'Invalid API Key') {
    super(message)
    this.name = 'HamrsSyncBadApiKeyError'
  }
}
interface HamrsCouchSubscription {
  url: string
  subscribed: boolean
}

const HamrsDotAppService = {
  async couchDbSubscription(key: string): Promise<HamrsCouchSubscription> {
    const response = await fetch(`${hamrsDotAppURL}/api/v1/couchdb_url`, {
      headers: { Authorization: `Token ${key}` }
    })

    if (!response.ok) {
      if (response.status === 401) {
        // Throw a specific error for invalid API keys
        throw new HamrsSyncBadApiKeyError()
      }

      // For other status codes, throw a generic error
      throw new Error(`Failed to configure sync: ${response.status} ${response.statusText}`)
    }

    const body = await response.json()

    return {
      url: body.url,
      subscribed: requireSync || body.subscribed
    }
  }
}

export default HamrsDotAppService