import type { LoggerConfigType, ToolbarFields, QsoTableColumnsType, LoggerPanels, FieldRow } from '@/types/LoggerConfigType';
import type { StickyFieldsType } from '@/types/StickyFieldsType';
import type { ColumnLayoutPropsType } from '@/types/ColumnLayoutPropsType';
import LoggerConfigRegistry from './LoggerConfigRegistry';
export class Logger {
  private config: LoggerConfigType;
  private static configRegistry = LoggerConfigRegistry.getInstance();
  // Getters for common config properties
  public get toolbarItems(): ToolbarFields[] {
    return this.config.toolbarItems;
  }

  public get toolbarPosition(): 'TOP' | 'BOTTOM' {
    return this.config.toolbarPosition;
  }

  public get tableColumns(): QsoTableColumnsType[] {
    return this.config.tableColumns;
  }

  public get panels(): LoggerPanels[] {
    return this.config.panels;
  }

  public get stickyFields(): StickyFieldsType[] {
    return this.config.stickyFields;
  }

  public get fieldRows(): FieldRow[] {
    return this.config.fieldRows;
  }

  public get templateId(): string {
    return this.config.templateId;
  }

  public get templateDescription(): string {
    return this.config.templateDescription;
  }

  constructor(configOrTemplateId: string | LoggerConfigType) {
    if (typeof configOrTemplateId === 'string') {
      // If string, treat as templateId
      this.config = Logger.configRegistry.getConfig(configOrTemplateId);
    } else {
      // If LoggerConfigType, use directly
      this.validateConfig(configOrTemplateId);
      this.config = configOrTemplateId;
    }
  }

  private validateConfig(config: LoggerConfigType): void {
    if (!config.templateId || !config.fieldRows) {
      throw new Error('Invalid configuration: must include templateId and fieldRows');
    }
  }

  public static registerConfig(config: LoggerConfigType): void {
    Logger.configRegistry.registerConfig(config);
  }

  public getConfig(): LoggerConfigType {
    return this.config;
  }

  public setConfig(config: LoggerConfigType): void {
    this.validateConfig(config);
    this.config = config;
    // Only register if it has a templateId
    if (config.templateId) {
      Logger.configRegistry.registerConfig(config);
    }
  }

  public formkitSchema(): any {
    const rows = this.config.fieldRows.map(row => {
      return {
        $cmp: "Row",
        props: {
          columns: row.columns,
          ...(row.classes ? { class: row.classes } : {})
        },
        children: row.fields.map(field => {
          const fieldInput: FormkitField = { $cmp: field.component }
          if (field.props) {
            fieldInput.props = field.props
          }
          if (field.columns) {
            fieldInput.props = {
              ...fieldInput.props,
              columns: field.columns
            }
          }
          if (field.label) {
            fieldInput.label = field.label
          }
          return fieldInput
        })
      }
    })
    return rows
  }
}

interface FormkitField {
  $cmp: string;
  props?: {
    columns?: ColumnLayoutPropsType;
  } & {
    [key: string]: string | number | ColumnLayoutPropsType;
  };
  label?: string;
}

export default Logger;