import isElectron from 'is-electron';
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import type { RouteRecordRaw } from 'vue-router';
import { requireSync } from '@/config';

export const LOGBOOKS_PAGE_ROUTE_NAME = "LogbooksPage";
export const LOGBOOK_PAGE_ROUTE_NAME = "LogbookPage";
export const EDIT_QSO_PAGE_ROUTE_NAME = "EditQsoPage";
export const QSO_LIST_PAGE_ROUTE_NAME = "QsoListPage";
export const OPERATOR_PAGE_ROUTE_NAME = "OperatorPage";
export const PROFILES_PAGE_ROUTE_NAME = "ProfilesPage";
export const EDIT_PROFILE_PAGE_ROUTE_NAME = "EditProfilePage";
export const EDIT_CUSTOM_LOGBOOK_TEMPLATE_PAGE_NAME = "EditCustomLogbookTemplatePage";
export const NEW_PROFILE_PAGE_ROUTE_NAME = "NewProfilePage";
export const CONFIGURATION_PAGE_NAME = "ConfigurationPage";
export const SYNC_PAGE_ROUTE_NAME = "SyncPage";
export const NOT_FOUND_PAGE_ROUTE_NAME = "404Page";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: LOGBOOKS_PAGE_ROUTE_NAME,
    component: () => import("@/pages/LogbooksPage.vue"),
  },
  {
    path: "/logbooks/:id",
    name: LOGBOOK_PAGE_ROUTE_NAME,
    component: () => import("@/pages/LogbookPage.vue"),
  },
  {
    path: "/logbooks/:logbookId/qsos/:id/edit",
    name: EDIT_QSO_PAGE_ROUTE_NAME,
    component: () => import("@/pages/EditQsoPage.vue"),
    meta: { showBackButton: true }
  },
  {
    path: "/qsos",
    name: QSO_LIST_PAGE_ROUTE_NAME,
    component: () => import("@/pages/QsoListPage.vue"),
    meta: { showBackButton: true }
  },
  {
    path: "/custom-logbook-templates/:id/edit",
    name: EDIT_CUSTOM_LOGBOOK_TEMPLATE_PAGE_NAME,
    component: () => import("@/pages/EditCustomLogbookTemplatePage.vue"),
    meta: { showBackButton: true }
  },
  {
    path: "/operator/:callsign",
    name: OPERATOR_PAGE_ROUTE_NAME,
    component: () => import("@/pages/OperatorPage.vue"),
    meta: { showBackButton: true }
  },
  {
    path: "/profiles",
    name: PROFILES_PAGE_ROUTE_NAME,
    component: () => import("@/pages/ProfilesPage.vue"),
    meta: { showBackButton: true }
  },
  {
    path: "/profiles/new",
    name: NEW_PROFILE_PAGE_ROUTE_NAME,
    component: () => import("@/pages/NewProfilePage.vue"),
  },
  {
    path: "/profiles/:id/edit",
    name: EDIT_PROFILE_PAGE_ROUTE_NAME,
    component: () => import("@/pages/EditProfilePage.vue"),
  },
  {
    path: "/config",
    name: CONFIGURATION_PAGE_NAME,
    component: () => import("@/pages/ConfigurationPage.vue"),
    meta: { showBackButton: true }
  }
];

// Conditionally add sync page if requireSync is false
if (!requireSync) {
  routes.push({
    path: "/sync",
    name: SYNC_PAGE_ROUTE_NAME,
    component: () => import("@/pages/SyncPage.vue"),
  });
}

// Add the 404 route as a catch-all, at the end of the routes array
routes.push({
  path: "/:pathMatch(.*)*",  // Catch-all route for undefined paths
  name: NOT_FOUND_PAGE_ROUTE_NAME,
  component: () => import("@/pages/404Page.vue"),  // Lazy-load the 404 page
});

// Create and export the router
export const router = createRouter({
  history: isElectron() ? createWebHashHistory() : createWebHistory(),
  routes,
});

export default router;