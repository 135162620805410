import PouchDB from '@/repos/PouchDB'
import type { SyncStatusType, SyncType } from '@/types'
import { SyncPouchRepo } from '@/repos/SyncPouchRepo'
import HamrsDotAppService from '@/services/HamrsDotApp'

let syncHandler: null | ReturnType<typeof PouchDB.sync> = null
let lastApiKey: string = ''
const inactiveSubscriptionErrorMessage = 'Sync Paused: hamrs.app subscription is inactive. Check your subscription, or remove your sync key.'
let statusCheckTimeout: NodeJS.Timeout | null = null

const statusCheckInterval = 1000 * 60 * 60 * 24

const SyncService = {
  find(): Promise<SyncType> {
    return SyncPouchRepo.find()
  },
  update(sync: Partial<SyncType>): Promise<SyncType> {
    return SyncPouchRepo.update(sync)
  },

  // configureSync checks if the API key stored in sync is valid and
  // enables PouchDB replication. It takes a callback function where
  // we report sync status so users can be notified if their subscription
  // lapses or the server fails etc.
  async configureSync(sync: SyncType, reportStatus: (status: SyncStatusType) => void) {
    if (statusCheckTimeout) {
      clearTimeout(statusCheckTimeout)
    }

    // If they get rid of their API Key then just disable sync and move on.
    if (!sync?.hamrs.apiKey) {
      this.disableSync()
      reportStatus({
        lastSynced: null,
        valid: true,
        error: ''
      })
      return
    }

    try {
      const subscription = await HamrsDotAppService.couchDbSubscription(sync.hamrs.apiKey)

      if (!subscription.subscribed) {
        this.disableSync()
        reportStatus({
          lastSynced: null,
          valid: false,
          error: inactiveSubscriptionErrorMessage
        })
        // Try to turn it back on again later. This will probably just result in
        // toasting the user again tomorrow, but that's ok.
        statusCheckTimeout = setTimeout(() => {
          this.configureSync(sync, reportStatus)
        }, statusCheckInterval)
        return
      }

      if (!subscription.url) {
        throw new Error('HAMRS Sync gave a malformed URL')
      }

      if (subscription.subscribed && lastApiKey != sync.hamrs.apiKey) {
        this.disableSync()
        lastApiKey = sync.hamrs.apiKey
        // Do a single 1-way pull from the remote db when we start up.
        await PouchDB.replicate.from(subscription.url)

        // Once that's done we start a live 2-way sync which will run in the
        // background and tells us about errors via our complete function.
        // Save the handler to so we can cancel it if they change the api key
        // again.
        syncHandler = PouchDB.sync(subscription.url, { live: true, retry: true })
        syncHandler
          .on('change', function () {
            // pouch: document changes detected
          })
          .on('paused', function () {
            // pouch: replication paused (e.g. replication up to date, user went offline)
            reportStatus({ lastSynced: new Date(), valid: true, error: '' })
          })
          .on('active', function () {
            // pouch: replication active (e.g. new changes replicating, user went back online)
          })
          .on('denied', function () {
            // pouch: a document failed to replicate (e.g. due to permissions)
          })
          .on('error', function () {
            // pouch: handle error
            // TODO Do we want to reportStatus here so we toast an error?
          })

        // queue a thing to recheck their status periodically and turn sync off if their subscription has lapsed.
        statusCheckTimeout = setTimeout(() => {
          this.disableSyncIfSubscriptionLapses(sync, reportStatus)
        }, statusCheckInterval)
      }
    } catch (error) {
      this.disableSync()
      console.error('Error in configureSync:', error)
      reportStatus({
        lastSynced: null,
        valid: false,
        error: error instanceof Error ? error.message : 'Failed to check subscription status'
      })
    }
  },

  disableSync() {
    if (syncHandler) {
      syncHandler.cancel()
      syncHandler = null
    }
    lastApiKey = ''
  },

  async disableSyncIfSubscriptionLapses(
    sync: SyncType,
    reportStatus: (status: SyncStatusType) => void
  ) {
    const subscription = await HamrsDotAppService.couchDbSubscription(sync.hamrs.apiKey)
    if (!subscription.subscribed) {
      // Whoops! They'd only be in this function if they had sync turned on
      // and working, so if their subscription is returning false then they expired.
      this.disableSync()
      reportStatus({
        lastSynced: null,
        valid: false,
        error: inactiveSubscriptionErrorMessage
      })
      // Try to turn it back on again later. This will probably just result in
      // toasting the user again tomorrow, but that's ok.
      statusCheckTimeout = setTimeout(() => {
        this.configureSync(sync, reportStatus)
      }, statusCheckInterval)
      return
    }

    // If they're here then they're still active. Check again in a minute.
    statusCheckTimeout = setTimeout(() => {
      this.disableSyncIfSubscriptionLapses(sync, reportStatus)
    }, statusCheckInterval)
  },

  async cancelSyncAndDestroy() {
    return new Promise<void>((resolve, reject) => {
      if (!syncHandler) {
        reject(new Error('syncHandler is null'))
        return
      }

      // Register a callback handler to wait for syncing to be complete.
      syncHandler.on('complete', async function () {
        // Data is done syncing, delete our local copy.
        await PouchDB.destroy()

        // We're done
        resolve()
      })

      // Tell pouch to stop syncing with our cloud db.
      syncHandler.cancel()
    }).catch((error) => {
      console.error('Error in cancelSyncAndDestroy:', error)
      throw error
    })
  }
}

export default SyncService
