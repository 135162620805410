<script setup lang="ts">
  import { ref } from 'vue'
  import { useRegisterSW } from 'virtual:pwa-register/vue'
  import RingingBellElement from '@/components/ui/RingingBellElement.vue';
  // Set period to check for updates (here: 5 minutes)
  const period = 60 * 5 * 1000
  const refreshing = ref(false)

  const swActivated = ref(false)

  /**
   * Function to periodically check for updates
   */
  function registerPeriodicSync(swUrl: string, r: ServiceWorkerRegistration) {
    if (period <= 0) return

    setInterval(async () => {
      if ('onLine' in navigator && !navigator.onLine)
        return

      const resp = await fetch(swUrl, {
        cache: 'no-store',
        headers: {
          'cache': 'no-store',
          'cache-control': 'no-cache',
        },
      })

      if (resp?.status === 200)
        await r.update()
    }, period)
  }

  const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW({
    immediate: true,
    onRegisteredSW(swUrl, r) {
      console.log('Service Worker registered:', r);

      if (period <= 0) return;

      if (r?.active?.state === 'activated') {
        console.log('Service Worker activated');
        swActivated.value = true;
        registerPeriodicSync(swUrl, r);
      } else if (r?.installing) {
        r.installing.addEventListener('statechange', (e) => {
          const sw = e.target as ServiceWorker;
          console.log('Service Worker state changed:', sw.state);
          swActivated.value = sw.state === 'activated';
          if (swActivated.value)
            registerPeriodicSync(swUrl, r);
        });
      }
    },
    onNeedRefresh() {
      console.log('New content available, showing the refresh prompt.');
      needRefresh.value = true;
      refreshing.value = false;
    },
    onOfflineReady() {
      console.log('App is ready to work offline.');
      offlineReady.value = true;
    }
  });
  function close() {
    refreshing.value = false
    offlineReady.value = false;
    needRefresh.value = false;
  }

  function handleRefresh() {
    refreshing.value = true
    updateServiceWorker()
  }
</script>

<template>
  <div v-if="needRefresh" class="fixed inset-x-0 bottom-0">
    <div
      class="flex items-center gap-x-6 bg-brand-secondary-500 dark:bg-slate-950 px-8 py-2.5 sm:px-3.5 sm:before:flex-1">
      <p class="text-sm leading-6 text-white">
        <RingingBellElement />
        <strong class="font-semibold ml-2">New Version Available</strong>
        <svg viewBox="0 0 2 2" class="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
          <circle cx="1" cy="1" r="1" />
        </svg>
        <span v-if="!refreshing">Save your work and <a href="#" class="underline" @click.prevent="handleRefresh">Click
            here</a> to
          update</span>
        <span v-else>Updating...</span>
      </p>
      <div class="flex flex-1 justify-end">
        <button @click="close()" type="button" class="-m-3 p-3 focus-visible:outline-offset-[-4px]">
          <span class="sr-only">Dismiss</span>
          <i class="fa fa-close text-white" />
        </button>
      </div>
    </div>
  </div>
</template>