// store/logbooks.ts
import { defineStore } from "pinia";
import LogbookService from "@/services/LogbookService";
import LogbookTemplate from "@/models/LogbookTemplate";
import type { LogbookType, DuplicateLogbookType } from "@/types";

export const useLogbookStore = defineStore("logbooks", {
  state: () => ({
    logbooks: [] as LogbookType[],
    customLogbookTemplates: [] as LogbookTemplate[],
  }),
  getters: {
    getLogbookTemplateById: (state) => {
      return (id: string): LogbookTemplate | undefined =>
        state.customLogbookTemplates.find(template => template._id === id);
    }
  },
  actions: {
    async fetchLogbookTemplates() {
      try {
        const templates = await LogbookTemplate.findAll();
        this.customLogbookTemplates = templates;
      } catch (error) {
        console.error("Error fetching logbook templates:", error);
      }
    },
    async fetchLogbooks() {
      try {
        const logbooks = await LogbookService.findAll();
        this.logbooks = logbooks;
      } catch (error) {
        console.error("Error fetching logbooks:", error);
      }
    },

    async createLogbook(newLogbookData: LogbookType) {
      try {
        const createdLogbook = await LogbookService.create({ attrs: newLogbookData });
        await this.fetchLogbooks();
        return createdLogbook;
      } catch (error) {
        console.error("Error creating logbook:", error);
        throw error;
      }
    },

    async updateLogbook(logbook: LogbookType) {
      try {
        const createdLogbook = await LogbookService.update(logbook);
        await this.fetchLogbooks();
        return createdLogbook;
      } catch (error) {
        console.error("Error updating logbook:", error);
        throw error;
      }
    },

    async deleteLogbook(id: string) {
      try {
        await LogbookService.delete(id);
        await this.fetchLogbooks();
        return;
      } catch (error) {
        console.error("Error deleting logbook:", error);
        throw error;
      }
    },

    async duplicateLogbook(attrs: DuplicateLogbookType) {
      try {
        await LogbookService.duplicate(attrs);
        await this.fetchLogbooks();
        return;
      } catch (error) {
        console.error("Error duplicating logbook:", error);
        throw error;
      }
    }
  },
});
