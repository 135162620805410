// LogbookPouchRepo.ts
import PouchDB from "@/repos/PouchDB";
import { LogbookTemplate, type LogbookTemplatePouchData } from "@/models/LogbookTemplate";
import type { BaseRepoInterface } from "@/interfaces";
import { v4 as uuidv4 } from "uuid";
import { LOGBOOK_TEMPLATE_ID_PREFIX } from "@/constants";

interface LogbookTemplateRepoInterface extends Omit<BaseRepoInterface<LogbookTemplate>, 'create'> {
  create(attrs: Partial<LogbookTemplate>): Promise<LogbookTemplate>;
  delete(id: string): Promise<LogbookTemplate>;
  update(logbook: Partial<LogbookTemplatePouchData>): Promise<LogbookTemplate>;
}

const LogbookTemplatePouchRepo: LogbookTemplateRepoInterface = {
  create: async (attrs: Partial<LogbookTemplate>): Promise<LogbookTemplate> => {
    console.log('Creating logbook template with params:', attrs);
    const id = `${LOGBOOK_TEMPLATE_ID_PREFIX}:${uuidv4()}`;
    const newLogbookTemplate = {
      _id: id,
      ...attrs,
    };

    await PouchDB.upsert(id, () => newLogbookTemplate);
    const logbookTemplate = await PouchDB.get(id);
    return new LogbookTemplate(logbookTemplate);
  },

  update: async (logbook: Partial<LogbookTemplatePouchData>): Promise<LogbookTemplate> => {
    if (!logbook._id) {
      throw new Error('Cannot update a logbook template without an ID');
    }
    try {
      // Get existing document first
      const existingDoc = await PouchDB.get(logbook._id);
      // Merge the changes
      const updatedDoc = {
        ...existingDoc,
        ...logbook,
        updatedAt: new Date()
      };
      await PouchDB.upsert(logbook._id, () => updatedDoc);
      const logbookDoc = await PouchDB.get(logbook._id);
      return new LogbookTemplate(logbookDoc);
    } catch (error) {
      throw new Error(`Failed to update logbook template: ${error}`);
    }
  },

  findAll: async (): Promise<LogbookTemplate[]> => {
    const result = await PouchDB.allDocs({
      include_docs: true,
      startkey: `${LOGBOOK_TEMPLATE_ID_PREFIX}:`,
      endkey: `${LOGBOOK_TEMPLATE_ID_PREFIX}:\ufff0`,
    });
    return result.rows.map((row) => {
      if (!row.doc) throw new Error('Document not found');
      return new LogbookTemplate(row.doc);
    });
  },
  findOne: async (id: string): Promise<LogbookTemplate> => {
    try {
      const logbookDoc = await PouchDB.get(id);
      return new LogbookTemplate(logbookDoc);
    } catch (error) {
      throw new Error(`Failed to find logbook template: ${error}`);
    }
  },
  delete: async (id: string): Promise<LogbookTemplate> => {
    try {
      const doc = await PouchDB.get(id);
      await PouchDB.remove(doc);
      return new LogbookTemplate(doc);
    } catch (error) {
      throw new Error(`Failed to delete logbook template: ${error}`);
    }
  },
};

export default LogbookTemplatePouchRepo;
