import type { LoggerConfigType } from '@/types/LoggerConfigType';
import PotaLoggerConfig from '@/configs/PotaLoggerConfig';
import GenericLoggerConfig from '@/configs/GenericLoggerConfig';
import SotaLoggerConfig from '@/configs/SotaLoggerConfig';
import WinterFieldDayLoggerConfig from '@/configs/WinterFieldDayLoggerConfig';

export default class LoggerConfigRegistry {
  private static instance: LoggerConfigRegistry;
  private configs: Map<string, LoggerConfigType>;

  private constructor() {
    this.configs = new Map();
    this.registerDefaultConfigs();
  }

  public static getInstance(): LoggerConfigRegistry {
    if (!LoggerConfigRegistry.instance) {
      LoggerConfigRegistry.instance = new LoggerConfigRegistry();
    }
    return LoggerConfigRegistry.instance;
  }

  private registerDefaultConfigs(): void {
    this.registerConfig(PotaLoggerConfig);
    this.registerConfig(GenericLoggerConfig);
    this.registerConfig(SotaLoggerConfig);
    this.registerConfig(WinterFieldDayLoggerConfig);
  }

  public registerConfig(config: LoggerConfigType): void {
    this.configs.set(config.templateId, config);
  }

  public getConfig(templateId: string): LoggerConfigType {
    const config = this.configs.get(templateId);
    if (!config) {
      throw new Error(`No configuration found for template ID: ${templateId}`);
    }
    return config;
  }

  public hasConfig(templateId: string): boolean {
    return this.configs.has(templateId);
  }

  public getAllTemplateIds(): string[] {
    return Array.from(this.configs.keys());
  }
}