import { defineStore } from "pinia";
import { useCallsignLookupsStore } from "@/stores/CallsignLookupStore";
import type { LookupServiceProviderType } from "@/types";
import { useLocalStorage } from '@vueuse/core'

export const useSettingStore = defineStore("settings", {
  state: () => ({
    lookupProvider: "HAMDB" as LookupServiceProviderType,
    isOnline: false,
    panelOpen: false,
    expandedMode: false,
    darkMode: useLocalStorage("pinia/setting/darkMode",
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ),
    doLookups: useLocalStorage("pinia/setting/doLookups", true),
    displayToasts: useLocalStorage("pinia/setting/displayToasts", true),
    activeProfileID: useLocalStorage("pinia/setting/activeProfileID", ""),
    logbookTableSort: useLocalStorage("pinia/tables/logbookTableSort", [{ "id": "createdAt", "desc": true }]),
    lastUsedTemplate: useLocalStorage("pinia/setting/lastUsedTemplate", "GENERIC"),
  }),
  actions: {
    setActiveProfileID(id: string) {
      this.activeProfileID = id;
    },
    setLookupProvider(provider: LookupServiceProviderType) {
      this.lookupProvider = provider;
      useCallsignLookupsStore().initProvider(provider);
    },
    toggleDoLookups() {
      this.doLookups = !this.doLookups;
    },
    toggleDisplayToasts() {
      this.displayToasts = !this.displayToasts;
    },
    togglePanel() {
      this.panelOpen = !this.panelOpen;
    },
    toggleDarkMode() {
      this.darkMode = !this.darkMode;
      if (this.darkMode) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    },

    initDarkMode() {
      if (this.darkMode) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    }
  },
});
