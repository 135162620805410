import { defineStore } from 'pinia'
import SyncService from '@/services/SyncService'
import type { SyncType, SyncStatusType } from '@/types'
import { requireSync, hamrsDotAppURL } from '@/config'
import { useToastSystem } from '@/composables/useToastSystem'

const { syncErrorToast } = useToastSystem()

export const useSyncStore = defineStore('sync', {
  state: () => ({
    sync: {} as SyncType,
    status: {} as SyncStatusType
  }),
  actions: {
    async load() {
      try {
        const sync = await SyncService.find()
        this.sync = sync
        await SyncService.configureSync(sync, this.syncComplete)
      } catch (error) {
        console.error('Error fetching sync:', error)
      }
    },

    async create(token: string) {
      const data = {
        hamrs: {
          apiKey: token
        }
      } as SyncType
      return this.update(data)
    },

    async update(newSyncData: SyncType) {
      try {
        const createdSync = await SyncService.update(newSyncData)
        await this.load()
        return createdSync
      } catch (error) {
        console.error('Error creating sync:', error)
        throw error
      }
    },

    // syncComplete gets called as a callback inside the SyncService whenever
    // replication catches up. This allows us to show the "last synced" time on
    // the SyncType page and other actions can be triggered off this event using
    // syncStore.$onAction
    async syncComplete(status: SyncStatusType) {
      this.status = status
      if (!status.valid) {
        syncErrorToast(status.error)
      }
    },

    async cancel() {
      // This is dangerous to do outside of the web flow so we have an extra
      // guard here.
      if (!requireSync) {
        return
      }
      try {
        await SyncService.cancelSyncAndDestroy()
        window.location.href = `${hamrsDotAppURL}/?sign_out=true`
      } catch (error) {
        console.error('Error fetching sync:', error)
      }
    }
  }
})
