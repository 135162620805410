import type { QsoType, LogbookType, LookupServiceResponseType } from "@/types";

import { defineStore } from "pinia";
import { watch } from "vue";
import { watchDebounced } from '@vueuse/core'

import LogbookService from "@/services/LogbookService";
import PotaParkService from "@/services/PotaParkService";
import LogbookTemplate from "@/models/LogbookTemplate";
import QsoService from "@/services/QsoService";
import { mapFlrigModeToCommonMode } from "@/services/FlrigService";
import QsoFactory from "@/factories/QsoFactory";
import LookupServiceResponseToQsoTransformer from "@/transformers/LookupServiceResponseToQsoTransformer";

import { useProfileStore } from "./ProfileStore";
import { useRigControlStore } from "@/stores/RigControlStore";
import { Logger } from "@/models/Logger";
import { storeToRefs } from "pinia";

export const useLoggerStore = defineStore("logger", {
  state: () => ({
    currentLogbook: null as LogbookType | null,
    currentQso: {} as QsoType,
    logger: null as Logger | null,
    expandedMode: false,
    timeIsLive: true,
    timer: null as any,
    qsoList: [] as QsoType[],
    stickyFields: {} as Partial<QsoType>,
    watchersInitialized: false,
    watcherStoppers: [] as (() => void)[],
  }),

  getters: {
    lastQsoByCreatedAt: (state): QsoType | null => {
      if (state.qsoList.length === 0) return null;
      // Find the QSO with the latest `createdAt`
      return state.qsoList.reduce((latest, qso) => {
        return new Date(qso.createdAt!) > new Date(latest.createdAt!) ? qso : latest;
      }, state.qsoList[0]);
    },

    lastQsoByDateTime: (state): QsoType | null => {
      if (state.qsoList.length === 0) return null;
      // Parse and compare `qsoDate` and `timeOn`
      return state.qsoList.reduce((latest, qso) => {
        return new Date(qso.qsoDateTime!) > new Date(latest.qsoDateTime!) ? qso : latest;
      }, state.qsoList[0]);
    },
    qsoListSortedByDateTime: (state): QsoType[] => {
      return state.qsoList.sort((a, b) => {
        const dateA = new Date(`${a.qsoDate}T${a.timeOn}`).getTime();
        const dateB = new Date(`${b.qsoDate}T${b.timeOn}`).getTime();
        return dateB - dateA;
      });
    },
  },

  actions: {
    setToInitialValues() {
      this.currentLogbook = null;
      this.currentQso = {} as QsoType;
      this.expandedMode = false;
      this.logger = null;
      this.timeIsLive = true;
      this.timer = null;
      this.qsoList = [];
      this.stickyFields = {};
      this.watchersInitialized = false;
      this.watcherStoppers = [];
    },
    initWatchers() {
      // Clear existing watchers
      this.clearWatchers();

      const rigControlStore = useRigControlStore();
      const { frequency, mode, power, rigControlAvailable } = storeToRefs(rigControlStore);

      // Watch frequency
      const stopFrequencyWatcher = watch(
        frequency,
        (newFrequency) => {
          if (newFrequency && rigControlAvailable.value) {
            const frequencyMHz = (newFrequency / 1_000_000);
            this.updateCurrentQso({ freq: frequencyMHz });
          }
        },
        { immediate: true }
      );

      // Watch mode
      const stopModeWatcher = watch(
        mode,
        (newMode) => {
          if (newMode && rigControlAvailable.value) {

            const normalizedMode = mapFlrigModeToCommonMode(newMode);
            this.updateCurrentQso({ mode: normalizedMode });
            this.updateRstValues();
          }
        },
        { immediate: true }
      );

      // Watch power
      const stopPowerWatcher = watch(
        power,
        (newPower) => {
          if (newPower !== null && newPower !== undefined && rigControlAvailable.value) {
            this.updateCurrentQso({ txPwr: newPower });
          }
        },
        { immediate: true }
      );

      // Watch currentQso.mode for RST updates
      const stopQsoModeWatcher = watch(
        () => this.currentQso.mode,
        (newMode) => {
          if (newMode) {
            this.updateRstValues();
          }
        },
        { immediate: true }
      );

      const stopMyParkWatcher = watchDebounced(
        () => this.currentQso.myPotaRef,
        (newRef, oldRef) => {
          if (newRef !== oldRef) {
            if (newRef || newRef == '') {
              this.updateCurrentQsoFromMyParkRef(newRef);
            }
          }
        },
        { debounce: 500 }
      );

      // Store stopper functions
      this.watcherStoppers.push(
        stopFrequencyWatcher,
        stopModeWatcher,
        stopPowerWatcher,
        stopQsoModeWatcher,
        stopMyParkWatcher
      );
    },

    clearWatchers() {
      this.watcherStoppers.forEach((stop) => stop());
      this.watcherStoppers = [];
    },

    clearForm() {
      if (this.currentQso && this.logger?.stickyFields) {
        const activeProfile = useProfileStore().activeProfile;
        const operatorCallsign = activeProfile?.operator.callsign || "";

        // Save the values of sticky fields
        const stickyValues = this.logger.stickyFields.reduce((acc, key) => {
          // @ts-ignore
          acc[key] = this.currentQso[key];
          return acc;
        }, {} as Partial<QsoType>);
        // Create new QSO with defaults and restore sticky values
        this.currentQso = {
          ...QsoFactory.newQso(
            this.currentLogbook!,
            stickyValues,
            operatorCallsign
          )
        }
      }
    },

    // Initialization and Reset
    async initLogger(logbookId: string) {
      this.stickyFields = {}
      await this.fetchCurrentLogbook(logbookId);
      if (this.currentLogbook) {
        if (this.currentLogbook.template != 'CUSTOM') {
          this.logger = new Logger(this.currentLogbook.template);
        } else if (this.currentLogbook?.templateId) {
          try {
            const customTemplate = await LogbookTemplate.findById(this.currentLogbook?.templateId);
            this.logger = new Logger(customTemplate.loggerConfig);
          } catch {
            this.logger = new Logger('GENERIC');
            return
          }

        } else {
          this.logger = new Logger('GENERIC');
          return
        }
        await this.fetchQsoList(logbookId);
        if (this.lastQsoByDateTime) {
          this.setStickyFields(
            this.currentLogbook.template,
            this.lastQsoByDateTime
          );
        }
        await this.initQso();
      }
      this.initWatchers();
    },

    async resetLogger() {
      if (this.currentLogbook) {
        await this.initQso();
      }
    },

    // Logbook Actions
    async fetchCurrentLogbook(logbookId: string) {
      const logbook = await LogbookService.findOne(logbookId);
      this.currentLogbook = logbook;
    },

    // QSO Actions
    async fetchQsoList(logbookId: string) {
      const qsos = await QsoService.findAllByLogbook(logbookId);
      this.qsoList = qsos || []
    },

    async setOperator(operator: string) {
      this.$patch({
        currentQso: {
          ...this.currentQso,
          operator: operator,
        },
      });
    },

    async initQso() {
      const activeProfile = useProfileStore().activeProfile;
      if (!this.currentLogbook) return;

      const previousQso = this.timeIsLive ? this.lastQsoByDateTime : this.lastQsoByCreatedAt;
      // Create a new QSO with initial values
      const newQso = QsoFactory.newQso(
        this.currentLogbook,
        this.stickyFields,
        activeProfile?.operator.callsign || ""
      );
      if (!this.timeIsLive && previousQso) {
        // When time is paused, carry over timeOn and qsoDate from the last QSO
        newQso.timeOn = previousQso.timeOn;
        newQso.qsoDate = previousQso.qsoDate;
      }

      // Initialize from RigControlStore
      const rigControlStore = useRigControlStore();
      if (rigControlStore.rigControlAvailable) {
        newQso.freq = (rigControlStore.frequency / 1_000_000) // Convert to MHz
        newQso.mode = rigControlStore.mode;
        newQso.txPwr = rigControlStore.power;
      }
      this.currentQso = newQso;
    },

    async updateCurrentQso(attrs: Record<string, string | number | boolean>) {
      if (this.currentQso) {
        this.$patch({
          currentQso: {
            ...this.currentQso,
            ...attrs,
          },
        });
      }
    },
    updateCurrentQsoFromLookupResponse(response: LookupServiceResponseType) {
      if (this.currentQso) {
        // Get the transformed response
        const transformedResponse =
          LookupServiceResponseToQsoTransformer(response);

        // Check if the currentQso has a park ref, if so, don't populate location
        // data with operator data
        if (this.currentQso.potaRef) {
          // If so, delete the gridsquare from the transformed response to avoid overwriting
          delete transformedResponse.gridsquare;
          delete transformedResponse.state;
          delete transformedResponse.qth;
          delete transformedResponse.cnty;
        }

        // Proceed to update currentQso with the potentially modified transformed response
        this.$patch({
          currentQso: {
            ...this.currentQso,
            ...transformedResponse,
          },
        });
      }
    },
    clearCurrentQsoList() {
      this.qsoList = [];
    },
    clearCurrentLogbook() {
      this.currentLogbook = null;
    },
    clearCurrentQso() {
      this.currentQso = {} as QsoType;
    },
    resetStore() {
      this.clearCurrentQsoList();
      this.clearCurrentLogbook();
      this.clearCurrentQso();
      this.clearTimer();
      this.clearWatchers();
      this.setToInitialValues()
    },
    clearCurrentQsoLookupFields() {
      if (this.currentQso) {
        // Destructure currentQso and set defaults for the properties you want to clear
        const {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          lookupSuccess,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          lookupService,
          ...restOfQso
        } = this.currentQso || {};

        this.$patch({
          currentQso: {
            ...restOfQso,
            lookedUp: false,
            name: '',
            gridsquare: '',
            state: '',
            qth: '',
            country: '',
            cnty: '',
          },
        });
      }
    },
    async saveCurrentQso() {
      if (this.currentQso && this.currentLogbook) {
        const potaRef = this.currentQso.potaRef;
        const callsign = this.currentQso.call;

        if ((potaRef && potaRef.includes(',')) || (callsign && callsign.includes(','))) {
          const savedQsos = await QsoService.batchCreate({
            attrs: this.currentQso,
            logbook: this.currentLogbook,
          });
          if (savedQsos && savedQsos.length > 0) {
            // Tanstack won't react if we don't do this 1 weird trick
            this.qsoList = [...this.qsoList, ...savedQsos];
          }
        } else {
          // Handle single QSO creation
          const savedQso = await QsoService.create({
            attrs: this.currentQso,
            logbook: this.currentLogbook,
          });
          if (savedQso) {
            LogbookService.update(this.currentLogbook);
            // Tanstack won't react if we don't do this 1 weird trick
            this.qsoList = [...this.qsoList, savedQso];
          }
        }

        // Reapply sorting if needed
        this.qsoList.sort((a, b) => {
          const dateA = new Date(`${a.qsoDate}T${a.timeOn}`).getTime();
          const dateB = new Date(`${b.qsoDate}T${b.timeOn}`).getTime();
          return dateB - dateA; // Ensure the list is sorted by date/time
        });

        // Set sticky fields for future QSOs
        this.setStickyFields(this.currentLogbook.template, this.currentQso);
      }
    },
    setStickyFields(formType: string, qso: QsoType) {
      if (this.logger && this.logger.stickyFields) {
        this.logger.stickyFields.forEach((field: any) => {
          const key = field as keyof QsoType;
          if (key in qso) {
            this.stickyFields[key] = qso[key] as any;
          }
        });
      }
    },

    async deleteQso(qsoId: string) {
      await QsoService.delete(qsoId);
      this.qsoList = this.qsoList.filter((qso) => qso._id !== qsoId);
    },

    // Time Management Actions
    updateCurrentQsoTimeOn() {
      const currentTime = new Date();
      const newTimeOn = currentTime.toISOString().substring(11, 19); // Time in HH:MM:SS
      const newQsoDate = currentTime.toISOString().substring(0, 10); // Date in YYYY-MM-DD

      this.$patch((state) => {
        // Update timeOn every second
        state.currentQso.timeOn = newTimeOn;

        // Only update qsoDate if it has changed (i.e., when a new day starts)
        if (state.currentQso.qsoDate !== newQsoDate) {
          state.currentQso.qsoDate = newQsoDate;
        }
      });
    },

    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },

    startLiveTime() {
      if (this.timer) return; // If timer is already running, avoid setting a new one
      this.clearTimer();       // Just in case, clear the previous timer
      this.timeIsLive = true;
      this.updateCurrentQsoTimeOn();
      this.timer = setInterval(() => {
        this.updateCurrentQsoTimeOn();
      }, 1000);
    },

    stopLiveTime() {
      this.clearTimer();
      this.timeIsLive = false;
    },

    toggleLiveTime() {
      this.timeIsLive ? this.stopLiveTime() : this.startLiveTime();
    },
    updateRstValues() {
      if (!this.currentQso) return;
      const rstModes = ['CW'];
      if (rstModes.includes(this.currentQso.mode!)) {
        if (this.currentQso.rstRcvd === '59') {
          this.currentQso.rstRcvd = '599';
        }
        if (this.currentQso.rstSent === '59') {
          this.currentQso.rstSent = '599';
        }
      } else {
        if (this.currentQso.rstRcvd === '599') {
          this.currentQso.rstRcvd = '59';
        }
        if (this.currentQso.rstSent === '599') {
          this.currentQso.rstSent = '59';
        }
      }
    },

    async updateCurrentQsoFromMyParkRef(myPotaRef: string) {
      if (myPotaRef == '') {
        this.updateCurrentQso({
          myGridsquare: '',
          myState: '',
          myCnty: ''
        })
        return
      }
      try {
        const park = await PotaParkService.fetchById(myPotaRef);
        const locations = park.location.split(',');
        let location = ''
        if (locations.length == 1) {
          location = locations[0].split('-')[1];
        }
        this.updateCurrentQso({
          myGridsquare: park.grid,
          myState: location,
          myCnty: ''
        })
      }
      catch (error) {
        this.updateCurrentQso({
          myGridsquare: '',
          myState: '',
          myCnty: ''
        })
      }
    },
  },
});
