<script setup lang="ts">
  const props = defineProps({
    error: {
      type: String,
      required: true,
    },
  });
</script>

<template>
  <div data-testid="toast__adif-export-error">
    <div class="font-bold text-sm mb-1" data-testid="toast__header">
      <span class="inline-flex items-center rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-bold uppercase"
        style="color: #ff5252" data-testid="toast__badge">
        Error
      </span>
      <span class="uppercase ml-2" data-testid="toast__title">ADIF Export Error</span>
    </div>
    <div data-testid="toast__body">
      {{ props.error }}
    </div>
  </div>
</template>
