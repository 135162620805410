import type { LoggerConfigType } from '@/types/LoggerConfigType';
import type { StickyFieldsType } from '@/types/StickyFieldsType';

export const WinterFieldDayLoggerConfig: LoggerConfigType = {
  templateId: 'ARRL_WFD',
  templateDescription: 'ARRL Winter Field Day',
  toolbarItems: ['TIME', 'FREQUENCY', 'MODE', 'TX_POWER', 'MY_CALLSIGN', 'MY_LOCATION'],
  toolbarPosition: 'TOP',
  panels: ['QSO_MAP'],
  tableColumns: ['qsoDateTime', 'call', 'rstSent', 'rstRcvd', 'state', 'freq', 'band', 'mode', 'qrzcomQsoUploadStatus'],
  stickyFields: [
    "freq",
    "band",
    "myPotaRef",
    "myGridsquare",
    "mode",
    "txPwr",
    "myCnty",
    "myState",
    "operator",
    "stationCallsign",
    "myArrlSect",
    "appHamrsMyClass"
  ] as unknown as StickyFieldsType[],
  fieldRows: [
    {
      columns: { base: 6 },
      fields: [
        { component: 'TheirCall', columns: { base: 2 } },
        { component: 'TextField', columns: { base: 1 }, props: { field: "class", label: "Their Class" } },
        { component: 'TextField', columns: { base: 1 }, props: { field: "arrlSect", label: "Their Section" } },
        { component: 'TextField', columns: { base: 1 }, props: { field: "appHamrsMyClass", label: "My Class" } },
        { component: 'TextField', columns: { base: 1 }, props: { field: "myArrlSect", label: "My Section" } },
      ]
    },
    {
      columns: { base: 2 },
      fields: [
        { component: 'Comments' },
        { component: 'Notes' },
      ]
    },
    {
      classes: "flex justify-end",
      fields: [
        { component: 'ClearButton' },
        { component: 'SaveButton' },
      ]
    }
  ],
}

export default WinterFieldDayLoggerConfig;